/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';

import logo from '../../assets/logo.png';
import Input from '../../components/ReactInput';
import { Container, FooterDisclaimer } from './styles';

function Login() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const schemaLogin = Yup.object().shape({
    usuario: Yup.string()
      .trim()
      .email('Email inválido')
      .test('usuario-error', 'Email inválido', value => !value.toLowerCase().includes('usuario'))
      .test('teste-error', 'Email inválido', value => !value.toLowerCase().includes('teste'))
      .test('teste-error', 'Email inválido', value => !value.toLowerCase().includes('test'))
      .required('Campo obrigatório'),
    password: Yup.string().required('Campo obrigatório'),
  });

  const handleSubmitLogin = useCallback(
    ({ usuario, password }) => {
      dispatch(signInRequest(usuario.trim(), password));
    },
    [dispatch],
  );

  return (
    <Container>
      <div>
        <div>
          <img src={logo} className="logo" alt="Unimed" />
          <Form schema={schemaLogin} id="login" className="login" tabIndex="500" onSubmit={handleSubmitLogin}>
            <div className="mail">
              <Input type="mail" id="usuario" name="usuario" placeholder="Usuário (seu email)" />
            </div>
            <div className="passwd">
              <Input type="password" id="password" name="password" placeholder="Senha" />
            </div>
            <div className="submit">
              <button className="dark" type="submit">
                {loading ? 'Carregando...' : 'Acessar'}
              </button>
            </div>
            <h6>
              <Link className="link" to="/recovery">
                Recuperar senha
              </Link>
            </h6>
            <h6>
              <Link className="link" to="/signup">
                Cadastrar
              </Link>
            </h6>
            <h6>
              <Link className="show-job" to="/jobs">
                Visualizar vagas abertas
              </Link>
            </h6>
          </Form>
        </div>
      </div>
      <FooterDisclaimer>
        <div>Desenvolvido por Tecnologia da Informação Unimed Varginha. </div>
        <a href="mailto:duvidas@vagas.unimedvarginha.coop.br" className="nao-responda">
          duvidas@vagas.unimedvarginha.coop.br
        </a>
      </FooterDisclaimer>
    </Container>
  );
}

export default memo(Login);
