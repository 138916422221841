/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useRef, Fragment, memo } from 'react';
import { useDispatch } from 'react-redux';

import BackIcon from '@material-ui/icons/ArrowBack';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { format, differenceInYears } from 'date-fns';
import { format as formatCPF } from 'gerador-validador-cpf';
import PropTypes from 'prop-types';

import { toggleLoading } from '~/store/modules/main/actions';
import { toggleSnack } from '~/store/modules/snack/actions';

import UniBreakLines from '../../../components/UniBreakLines';
import { niveis as languageLevel } from '../../../configs/helpers/languagelevel';
import { niveis as skilllevel } from '../../../configs/helpers/skilllevel';
import { storage } from '../../../firebase';
import { formatPhoneNumber } from '../../../functions/curriculos';
import api from '../../../services/api';
import { Container, DownloadButton, BackButton, DashContainer, ButtonContainer, RightItemsContainer } from './styles';


const AdminResumeViewer = ({ location: { search } }) => {
  const [dados, setDados] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailUsuario, setEmailUsuario] = useState('');
  const [, setEtapasProcessoSeletivo] = useState([]);
  const [, setExistePreAnalise] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [telefone, setTelefone] = useState('');
  const printRef = useRef();
  const params = new URLSearchParams(search);
  const userId = params.get('id');
  const [, setProcessosAbertos] = useState([]);
  const [processoSeletivoId] = useState(null);
  const [, setTiposClassificacaoCurriculo] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(toggleLoading(true));
      try {
        const resposta = await api.get(`userresumes?id=${userId}`);
        const { data } = resposta;
        if (data.length === 0) return;
        const { resumeData, ...othersInfo } = data;
        const resumeObject = { ...resumeData, ...othersInfo };
        setDados(resumeObject);
        const {
          telefone1,
          usuario: { nome, email },
        } = resumeObject;

        const nomeDividido = nome.split(' ');
        setFirstName(nomeDividido[0]);
        nomeDividido.splice(0, 1);
        setLastName(nomeDividido.join(' '));
        setEmailUsuario(email);
        setTelefone(telefone1);
        const { data: preAnaliseExistente } = await api.get(`preanalisecurriculo/curriculo/${data.id}`);
        setExistePreAnalise(preAnaliseExistente && preAnaliseExistente.data && preAnaliseExistente.data.length > 0);

        try {
          const userImageUrl = await storage.doUserPhotoUrlLink(userId);
          setImageUrl(userImageUrl || null);
        } catch (error) {
          // do nothing
        }
      } catch (err) {
        dispatch(toggleSnack(true, `Erro ao carregar dados do curriculo ${err}`, 'error-on-load', 'error'));
      }
      dispatch(toggleLoading(false));
    };
    fetchData();
  }, [dispatch, userId]);

  useEffect(() => {
    const fecthData = async () => {
      const { data } = await api.get(`processoseletivo/active`);
      if (data.length === 0) return;
      data.forEach(item => {
        setProcessosAbertos(processo => [...processo, { id: item.id, title: item.nome }]);
      });
    };
    fecthData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get('tipoClassificacaoCurriculo');
      if (data.length === 0) return;
      data.forEach(item => [setTiposClassificacaoCurriculo(tipo => [...tipo, { id: item.id, title: item.nome }])]);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!processoSeletivoId) return;
      const { data } = await api.get(`etapaprocessoseletivo/processoSeletivo/${processoSeletivoId}`);
      if (data.data.length === 0) return;
      data.data.forEach(item => setEtapasProcessoSeletivo(etp => [...etp, { id: item.id, title: item.etapa.nome }]));
    };
    fetchData();
  }, [processoSeletivoId]);

  const printDocument = () => {
    global.window.print();
  };

  return (

    <DashContainer>


      <Container ref={printRef}>

        <div className="header">
          <div className="full-name">
            <span className="first-name">{firstName}</span>
            <span className="last-name"> {lastName}</span>
            {imageUrl && <img className="user-photo" src={imageUrl} alt="Foto do Candidato" />}
          </div>
          <div className="contact-info">
            <span className="email">Email: </span>
            <span className="email-val">{emailUsuario}</span>
            <span className="separator" />
            <span className="phone">Telefone: </span>
            <span className="phone-val">{formatPhoneNumber(telefone)}</span>
            <span className="separator" />
            <span className="phone-val">{formatPhoneNumber(dados.telefone2)}</span>
          </div>
          <div className="contact-info">
            <span className="email">CPF: </span>
            <span className="email-">{dados.usuario && formatCPF(dados.usuario.cpf)}</span>
            <span className="separator" />
            <span className="email">RG: </span>
            <span className="email-val">{dados.rg}</span>
            <span className="separator" />
            <span className="email">PIS/PASEP: </span>
            <span className="email-val">{dados.pis}</span>
            <span className="separator" />
          </div>
          <div className="contact-info">
            <span className="phone">Idade: </span>
            <span className="phone-val">{differenceInYears(new Date(), new Date(dados.dataNascimento))} anos</span>
            <span className="separator" />
            <span className="phone">Estado Civil: </span>
            <span className="phone-val">{dados.estadoCivil && dados.estadoCivil.nome}</span>
            <span className="separator" />
            <span className="phone">Sexo: </span>
            <span className="phone-val">{dados.sexo && dados.sexo.nome}</span>
            <span className="separator" />
            <span className="phone">Carteira de Habilitação: </span>
            <span className="phone-val">{dados.cnh ? dados.cnh : 'Não possuo'}</span>
          </div>
          <div className="contact-info">
            <span className="phone">Endereço: </span>
            <span className="phone-val">
              {dados.logradouro}, {dados.numero}, {dados.bairro} - {dados.cidade}.{dados.uf}
            </span>
          </div>
          <div className="contact-info">
            <span className="email">Possui Filhos: </span>
            <span className="email-val">{dados.possuiFilhos ? 'Sim' : 'Não'}</span>
            <span className="separator" />
            <span className="email">Disponibilidade para Turnos: </span>
            <span className="email-val">{dados.disponibilidadeHorarioTurno ? 'Sim' : 'Não'}</span>
            <span className="separator" />
            <span className="email">Pessoa Com Deficiência: </span>
            <span className="email-val">{dados.portadorNecessidadeEspecial ? 'Sim' : 'Não'} </span>
            {dados.portadorNecessidadeEspecial && <span className="email-val">CID: {dados.cidNecessidadeEspecial}</span>}
            <span className="separator" />
          </div>
          <div className="contact-info">
            <span className="email">Amigo ou Parente na empresa: </span>
            <span className="email-val">{dados.possuiAmigoOuFamiliarNaEmpresa ? 'Sim' : 'Não'} </span>
            <span className="email">
              {dados.nomeAmigoOuFamiliarNaEmpresa} {dados.grauParentescoAmigoOuFamiliarNaEmpresa}
            </span>
            <span className="separator" />
          </div>
          <div className="contact-info">
            <span className="email">Está trabalhando atualmente: </span>
            <span className="email-val">{dados.estaTrabalhandoAtualmente ? 'Sim' : 'Não'}</span>
            <span className="separator" />
            <span className="email">Pretensão Salarial: </span>
            <span className="email-val">
              R$ {dados.pretensaoSalarial && dados.pretensaoSalarial.toString().replace('.', ',')}
            </span>
            <span className="separator" />
          </div>
          <div className="contact-info">
            <span className="email">Escolaridade: </span>
            <span className="email-val">{dados.escolaridade && dados.escolaridade.nome}</span>
          </div>
        </div>
        <div className="details">
          <div className="section">
            <div className="section__title">Áreas de Interesse</div>
            <div className="section__list">
              {dados.setoresCurriculo &&
                dados.setoresCurriculo.map(item => (
                  <Fragment key={item.id}>
                    <span>{item.setores.nome}</span>
                    <br />
                  </Fragment>
                ))}
            </div>
          </div>
          <div className="section">
            {dados.historicoProfissional && dados.historicoProfissional.length > 0 && (
              <>
                <div className="section__title">Histórico Profissional</div>
                <div className="section__list">
                  {dados.historicoProfissional.map(item => (
                    <div className="section__list-item" key={item.id}>
                      <div className="left">
                        <div className="name">{item.empresa}</div>
                        <div className="duration">
                          {item.dataInicial && format(new Date(item.dataInicial), 'MM/yyyy')} -{' '}
                          {item.empregoAtual ? 'Emprego Atual' : item.dataFinal && format(new Date(item.dataFinal), 'MM/yyyy')}
                        </div>
                        <div className="addr">
                          <UniBreakLines text={item.descricaoAtividades} />
                        </div>
                      </div>
                      <div className="right">
                        <div className="name">{item.cargo}</div>
                        <div className="desc">{item.setor}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="section">
            {dados.historicoEducacional && dados.historicoEducacional.length > 0 && (
              <>
                <div className="section__title">Histórico Educacional</div>
                <div className="section__list">
                  {dados.historicoEducacional.map(item => (
                    <div className="section__list-item" key={item.id}>
                      <div className="left">
                        <div className="name">{item.nomeInstituicao}</div>
                        <div className="addr">{item.cidade}</div>
                        <div className="duration">{item.descricao}</div>
                      </div>
                      <div className="right">
                        <div className="name">{item.graduacao && item.graduacao.nome}</div>
                        <div className="desc">
                          {format(new Date(item.inicioGraduacao), 'MM/yyyy')} - {format(new Date(item.fimGraduacao), 'MM/yyyy')}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="section">
            {dados.historicoCursos && dados.historicoCursos.length > 0 && (
              <>
                <div className="section__title">Histórico de Cursos</div>
                <div className="section__list">
                  {dados.historicoCursos &&
                    dados.historicoCursos.map(item => (
                      <div className="section__list-item" key={item.id}>
                        <div className="left">
                          <div className="name">{item.instituicao}</div>
                          <div className="duration">{item.descricao}</div>
                        </div>
                        <div className="right">
                          <div className="name">{item.nome}</div>
                          <div className="desc">
                            {format(new Date(item.dataInicio), 'MM/yyyy')} - {format(new Date(item.dataFim), 'MM/yyyy')}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
          <div className="section">
            {dados.historicoHabilidades && dados.historicoHabilidades.length > 0 && (
              <>
                <div className="section__title">Habilidades</div>
                <div className="skills">
                  {dados.historicoHabilidades &&
                    dados.historicoHabilidades.map(item => {
                      const level = skilllevel.filter(skill => skill.value === item.nivel);
                      return (
                        <div className="skills__item" key={item.id}>
                          <div className="left">
                            <div className="section__list">
                              {item.nome} em nível {level[0] && level[0].label}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
          <div className="section">
            {dados.historicoIdiomas && dados.historicoIdiomas.length > 0 && (
              <>
                <div className="section__title">Idiomas</div>
                <div className="section__list">
                  {dados.historicoIdiomas &&
                    dados.historicoIdiomas.map(item => {
                      const level = languageLevel.filter(lang => lang.value === item.nivel);
                      return (
                        <div className="section__list-item" key={item.id}>
                          {item.nome} nível {level[0] && level[0].label}
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
      <RightItemsContainer>
        <ButtonContainer>
          <DownloadButton type="button" onClick={() => printDocument()}>
            <DownloadIcon />
            Imprimir / Baixar
          </DownloadButton>

          <BackButton type="button" onClick={() => global.window.close()}>
            <BackIcon />
            <span>Fechar</span>
          </BackButton>
        </ButtonContainer>
      </RightItemsContainer>
    </DashContainer>
  );
};
AdminResumeViewer.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default memo(AdminResumeViewer);
