/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useRef, Fragment, memo, useCallback } from 'react';

import BackIcon from '@material-ui/icons/ArrowBack';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { format, differenceInYears } from 'date-fns';
import { useSnackbar } from 'notistack';

import { store } from '~/store';

import UniBreakLines from '../../../components/UniBreakLines';
import { niveis as languageLevel } from '../../../configs/helpers/languagelevel';
import { niveis as skilllevel } from '../../../configs/helpers/skilllevel';
import { getUTCDate, formatPhoneNumber } from '../../../functions/curriculos';
import api from '../../../services/api';
import { Container, DownloadButton, BackButton, DashContainer, LoadContainer } from './styles';

const ResumeViewer = () => {
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailUsuario, setEmailUsuario] = useState('');
  const [telefone, setTelefone] = useState('');
  const printRef = useRef();

  const { profile: userData } = store.getState().user;

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const resposta = await api.get(`userresumes?id=${userData.id}`);

      const { data } = resposta;
      if (data.length === 0) return;
      const { resumeData, ...othersInfo } = data;
      const resumeObject = { ...resumeData, ...othersInfo };
      setDados(resumeObject);
      const {
        telefone1,
        usuario: { nome, email },
      } = resumeObject;

      const nomeDividido = nome.split(' ');
      setFirstName(nomeDividido[0]);
      nomeDividido.splice(0, 1);
      setLastName(nomeDividido.join(' '));
      setEmailUsuario(email);
      setTelefone(telefone1);
    } catch (err) {
      enqueueSnackbar(`Erro ao carregar dados do curriculo ${err.message}`, { variant: 'error' });
    }
    setLoading(false);
  }, [enqueueSnackbar, userData.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const printDocument = () => {
    global.window.print();
  };

  return (
    <DashContainer>
      {!loading ? (
        <>
          <DownloadButton type="button" onClick={() => printDocument()}>
            <DownloadIcon />
            Imprimir / Baixar
          </DownloadButton>
          <BackButton type="button" onClick={() => global.window.close()}>
            <BackIcon />
            <span>Fechar</span>
          </BackButton>
          <Container ref={printRef}>
            <div>
              <div className="header">
                <div className="full-name">
                  <span className="first-name">{firstName}</span>
                  <span className="last-name"> {lastName}</span>
                </div>
                <div className="contact-info">
                  <span className="email">Email: </span>
                  <span className="email-val">{emailUsuario}</span>
                  <span className="separator" />
                  <span className="phone">Telefone: </span>
                  <span className="phone-val">{formatPhoneNumber(telefone)}</span>
                  <span className="separator" />
                  <span className="phone-val">{formatPhoneNumber(dados.telefone2)}</span>
                </div>
                <div className="contact-info">
                  <span className="phone">Idade: </span>
                  <span className="phone-val">{differenceInYears(new Date(), new Date(dados.dataNascimento))} anos</span>
                  <span className="separator" />
                  <span className="phone">Estado Civil: </span>
                  <span className="phone-val">{dados.estadoCivil && dados.estadoCivil.nome}</span>
                  <span className="separator" />
                  <span className="phone">Carteira de Habilitação: </span>
                  <span className="phone-val">{dados.cnh ? dados.cnh : 'Não possuo'}</span>
                </div>
                <div className="contact-info">
                  <span className="phone">Endereço: </span>
                  <span className="phone-val">
                    {dados.logradouro}, {dados.numero}, {dados.bairro} - {dados.cidade}.{dados.uf}
                  </span>
                </div>
              </div>
              <div className="details">
                <div className="section">
                  <div className="section__title">Áreas de Interesse </div>
                  <div className="section__list">
                    {dados.setoresCurriculo &&
                      dados.setoresCurriculo.map(item => (
                        <Fragment key={item.id}>
                          <span>{item.setores.nome}</span>
                          <br />
                        </Fragment>
                      ))}
                  </div>
                </div>
                <div className="section">
                  {dados.historicoProfissional && dados.historicoProfissional.length > 0 && (
                    <>
                      <div className="section__title">Histórico Profissional</div>
                      <div className="section__list">
                        {dados.historicoProfissional.map(item => (
                          <div className="section__list-item" key={item.id}>
                            <div className="left">
                              <div className="name">{item.empresa}</div>
                              <div className="duration">
                                {item.dataInicial && format(getUTCDate(item.dataInicial), 'MM/yyyy')} -{' '}
                                {item.empregoAtual
                                  ? 'Emprego Atual'
                                  : item.dataFinal && format(getUTCDate(item.dataFinal), 'MM/yyyy')}
                              </div>
                              <div className="addr">
                                <UniBreakLines text={item.descricaoAtividades} />
                              </div>
                            </div>
                            <div className="right">
                              <div className="name">{item.cargo}</div>
                              <div className="desc">{item.setor}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                <div className="section">
                  {dados.historicoEducacional && dados.historicoEducacional.length > 0 && (
                    <>
                      <div className="section__title">Histórico Educacional</div>
                      <div className="section__list">
                        {dados.historicoEducacional.map(item => (
                          <div className="section__list-item" key={item.id}>
                            <div className="left">
                              <div className="name">{item.nomeInstituicao}</div>
                              <div className="addr">{item.cidade}</div>
                              <div className="duration">{item.descricao}</div>
                            </div>
                            <div className="right">
                              <div className="name">{item.graduacao && item.graduacao.nome}</div>
                              <div className="desc">
                                {format(getUTCDate(item.inicioGraduacao), 'yyyy')} -{' '}
                                {format(getUTCDate(item.fimGraduacao), 'yyyy')}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                <div className="section">
                  {dados.historicoCursos && dados.historicoCursos.length > 0 && (
                    <>
                      <div className="section__title">Histórico de Cursos</div>
                      <div className="section__list">
                        {dados.historicoCursos &&
                          dados.historicoCursos.map(item => (
                            <div className="section__list-item" key={item.id}>
                              <div className="left">
                                <div className="name">{item.instituicao}</div>
                                <div className="duration">{item.descricao}</div>
                              </div>
                              <div className="right">
                                <div className="name">{item.nome}</div>
                                <div className="desc">
                                  {format(getUTCDate(item.dataInicio), 'MM/yyyy')} - {format(getUTCDate(item.dataFim), 'MM/yyyy')}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
                <div className="section">
                  {dados.historicoHabilidades && dados.historicoHabilidades.length > 0 && (
                    <>
                      <div className="section__title">Habilidades</div>
                      <div className="skills">
                        {dados.historicoHabilidades &&
                          dados.historicoHabilidades.map(item => {
                            const level = skilllevel.filter(skill => skill.value === item.nivel);
                            return (
                              <div className="skills__item" key={item.id}>
                                <div className="left">
                                  <div className="section__list">
                                    {item.nome} em nível {level[0] && level[0].label}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>
                <div className="section">
                  {dados.historicoIdiomas && dados.historicoIdiomas.length > 0 && (
                    <>
                      <div className="section__title">Idiomas</div>
                      <div className="section__list">
                        {dados.historicoIdiomas &&
                          dados.historicoIdiomas.map(item => {
                            const level = languageLevel.filter(lang => lang.value === item.nivel);
                            return (
                              <div className="section__list-item" key={item.id}>
                                {item.nome} nível {level[0] && level[0].label}
                              </div>
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </>
      ) : (
        <LoadContainer>Montando Currículo...</LoadContainer>
      )}
    </DashContainer>
  );
};

export default memo(ResumeViewer);
