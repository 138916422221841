/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useRef, useMemo, useCallback, memo } from 'react';
import { Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { MdInfo, MdCloudDone, MdCached, MdArrowDownward, MdDelete, MdCreate } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form, Scope } from '@rocketseat/unform';
import { useTimeout } from 'beautiful-react-hooks';
import * as cep from 'cep-promise';
import { format } from 'date-fns';
import { format as formatCPF } from 'gerador-validador-cpf';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

import { DatePickerMaterial, InputProgress, ReactRadioBig, ReactSelect, ReactSwitch } from '../../components/';
import ReactCropper from '~/components/ReactCroper';
import { mountOptionList } from '~/functions';
import useToggle from '~/hooks/useToggle';
import { store } from '~/store';
import {
  toggleLoading,
  setCurrentPage,
  setRequiredFieldsNotification,
  setUserPhotoNotification,
  setAutoSaveNotification,
  setDownloadNotification,
} from '~/store/modules/main/actions';
import { updateUserImageURL } from '~/store/modules/user/actions';

import UserProfileDefaultImage from '../../assets/user.png';
import * as uf from '../../configs/helpers/uf.json';
import * as routes from '../../configs/Routes';
import { storage } from '../../firebase';
import * as funcoes from '../../functions/curriculos';
import api from '../../services/api';
import HistoricoCursos from './HistoricoCursos';
import HistoricoEducacional from './HistoricoEducacional';
import HistoricoHabilidades from './HistoricoHabilidades';
import HistoricoIdiomas from './HistoricoIdiomas';
import HistoricoProfissional from './HistoricoProfissional';
import {
  Container,
  Resume,
  Preview,
  DocumentContainer,
  SubDocumentContainer,
  DocumentHeader,
  Document,
  DocumentPosition,
  ButtonAdd,
  ItemDescription,
  InputResume,
  SaveButton,
  MobileSaveStatus,
} from './styles';

const Curriculos = () => {
  const [carregando, setCarregando] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [dados, setDados] = useState([]);
  const [escolaridades, setEscolaridades] = useState([]);
  const [estados, setEstados] = useState([]);
  const [estadosCivis, setEstadosCivis] = useState([]);
  const historicosCursos = useMemo(() => dados.historicoCursos, [dados.historicoCursos]);
  const historicosEducacionais = useMemo(() => dados.historicoEducacional, [dados.historicoEducacional]);
  const historicosHabilidades = useMemo(() => dados.historicoHabilidades, [dados.historicoHabilidades]);
  const historicosIdiomas = useMemo(() => dados.historicoIdiomas, [dados.historicoIdiomas]);
  const historicosProfissionais = useMemo(() => dados.historicoProfissional, [dados.historicoProfissional]);
  const [quantidadeCamposPreenchidos, setQuantidadeCamposPreenchidos] = useState(0);
  const porcentagem = useMemo(() => parseInt((quantidadeCamposPreenchidos / 45) * 100), [quantidadeCamposPreenchidos]);
  const [regimesContratacoes, setRegimesContratacoes] = useState([]);
  const [regime, setRegime] = useState(2);
  const [refreshIframe, setRefreshIframe] = useState(0);
  const [salvando, setSalvando] = useState(false);
  const [setores, setSetores] = useState([]);
  const [sexos, setSexos] = useState([]);
  const [possuiCnh, toggleCnh] = useToggle();
  const [portadorNecessidadesEspeciais, setPortadorNecessidadesEspeciais] = useState(false);
  const [possuiAmigoOuFamiliar, setPossuiAmigoOuFamiliar] = useState(false);
  const [, setPossuiFilho] = useState(false);
  const [, setTrabalhandoAtualmente] = useState(false);
  const [, setTurno] = useState(false);
  const [, setVagasTemporarias] = useState(false);
  const containerRef = useRef(null);
  const printRef = useRef(null);
  const submitButton = useRef(null);
  const { profile: userData } = store.getState().user;
  const urlResumeViewer = useMemo(() => `/resume-viewer/?location=${userData.id}`, [userData.id]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [userPhoto, setUserPhoto] = useState(null);
  const preview = useMemo(() => userPhoto || null, [userPhoto]);
  const [showCropper, setShowCropper] = useState(false);

  const fecthUserPhoto = useCallback(async () => {
    try {
      const imageUrl = await storage.doUserPhotoUrlLink(userData.id);
      setUserPhoto(imageUrl);
    } catch (error) {
      // do nothing
    }
  }, [userData.id]);

  const { requiredFieldsNotification, userPhotoNotification, autoSaveNotification, downloadNotification } = useSelector(
    state => state.main,
  );

  useEffect(() => {
    dispatch(setCurrentPage('Meu currículo'));

    mountOptionList('escolaridade', setEscolaridades);
    mountOptionList('regimecontratacao', setRegimesContratacoes);
    mountOptionList('setores', setSetores);
    mountOptionList('sexo', setSexos);
    mountOptionList('estadocivil', setEstadosCivis);

    fecthUserPhoto();
    uf.UF.forEach(item => {
      setEstados(est => [...est, { id: item.sigla, title: item.nome }]);
    });
  }, [dispatch]); // eslint-disable-line

  const loadData = useCallback(
    async resumeData => {
      setCarregando(true);
      try {
        const {
          historicoProfissional,
          historicoEducacional,
          historicoCursos,
          historicoHabilidades,
          historicoIdiomas,
          usuario,
          dataNascimento,
          ...userInfo
        } = resumeData;

        const {
          cpf,
          regimeContratacaoId,
          possuiFilhos,
          cnh,
          portadorNecessidadeEspecial,
          disponibilidadeHorarioTurno,
          possuiAmigoOuFamiliarNaEmpresa,
          estaTrabalhandoAtualmente,
          pretensaoSalarial,
          vagaTemporaria,
        } = userInfo;

        setDados({
          ...userInfo,
          cpf: formatCPF(cpf),
          dataNascimento: dataNascimento || new Date(),
          usuario,
          historicoProfissional,
          historicoEducacional,
          historicoCursos,
          historicoHabilidades,
          historicoIdiomas,
          pretensaoSalarial: pretensaoSalarial && pretensaoSalarial.toString().replace('.', ','),
        });

        setPortadorNecessidadesEspeciais(Boolean(portadorNecessidadeEspecial));
        setPossuiAmigoOuFamiliar(Boolean(possuiAmigoOuFamiliarNaEmpresa));

        if (cnh) {
          toggleCnh();
        }

        setPossuiFilho(Boolean(possuiFilhos));
        setRegime(regimeContratacaoId);
        setTrabalhandoAtualmente(Boolean(estaTrabalhandoAtualmente));
        setTurno(Boolean(disponibilidadeHorarioTurno));
        setVagasTemporarias(Boolean(vagaTemporaria));
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setCarregando(false);
      }
    },
    [enqueueSnackbar, toggleCnh],
  );

  const fetchDataGeneral = useCallback(async () => {
    if (firstLoad) {
      dispatch(toggleLoading(true));
    }
    const resposta = await api.get(`curriculos/resume/${userData.id}`);
    const { data } = resposta;
    const { resumeData, ...othersInfo } = data;
    const resumeObject = { ...resumeData, ...othersInfo };

    loadData(resumeObject);
    if (firstLoad) {
      dispatch(toggleLoading(false));
      setFirstLoad(false);
      setRefreshIframe(funcoes.getRandomInt(1000));
    }
  }, [dispatch, firstLoad, loadData, userData.id]);

  useTimeout(() => {
    if (requiredFieldsNotification) return;
    enqueueSnackbar(`Ao preencher o currículo verifique se todos os campos obrigatórios estão preenchidos`, {
      variant: 'info',
    });
    dispatch(setRequiredFieldsNotification(true));
  }, 7000);

  useTimeout(() => {
    if (userPhotoNotification) return;
    enqueueSnackbar('Você pode cadastrar sua foto no seu perfil, clicando na imagem no cabeçalho desta página');
    dispatch(setUserPhotoNotification(true));
  }, 12000);

  useTimeout(() => {
    if (autoSaveNotification) return;
    enqueueSnackbar('Seus dados agora são salvos automaticamente', { variant: 'warning' });
    dispatch(setAutoSaveNotification(true));
  }, 17000);

  useTimeout(() => {
    if (downloadNotification) return;
    enqueueSnackbar('Acessando pelo computador você consegue baixar uma cópia do seu currículo formatado', { variant: 'info' });
    dispatch(setDownloadNotification(true));
  }, 22000);

  useTimeout(() => {
    enqueueSnackbar('Faça uma boa ação, e divulgue para seus amigos nosso sistema.', { variant: 'warning' });
    dispatch(setDownloadNotification(true));
  }, 27000);

  useEffect(() => {
    fetchDataGeneral();
  }, []); // eslint-disable-line

  useEffect(() => {
    try {
      if (dados !== undefined && dados?.length !== 0) {
        setQuantidadeCamposPreenchidos(0);
        setQuantidadeCamposPreenchidos(qte =>
          dados?.cep !== null && dados?.cep !== undefined && dados?.cep !== '' ? qte + 1 : qte + 0,
        );
        setQuantidadeCamposPreenchidos(qte =>
          dados?.cidade !== null && dados?.cidade !== undefined && dados?.cidade !== '' ? qte + 4 : qte + 0,
        );
        setQuantidadeCamposPreenchidos(qte =>
          dados?.dataNascimento !== null && dados?.dataNascimento !== undefined && dados?.dataNascimento !== ''
            ? qte + 4
            : qte + 0,
        );
        setQuantidadeCamposPreenchidos(qte =>
          dados?.escolaridadeId !== null && dados?.escolaridadeId !== undefined && dados?.escolaridadeId !== ''
            ? qte + 4
            : qte + 0,
        );
        setQuantidadeCamposPreenchidos(qte =>
          dados?.estadoCivilId !== null && dados?.estadoCivilId !== undefined && dados?.estadoCivilId !== '' ? qte + 1 : qte + 0,
        );
        setQuantidadeCamposPreenchidos(qte =>
          dados?.pis !== null && dados?.pis !== undefined && dados?.pis !== '' ? qte + 1 : qte + 0,
        );
        setQuantidadeCamposPreenchidos(qte =>
          dados?.pretensaoSalarial !== null && dados?.pretensaoSalarial !== undefined && dados?.pretensaoSalarial !== 0
            ? qte + 1
            : qte + 0,
        );
        setQuantidadeCamposPreenchidos(qte =>
          dados?.regimeContratacaoId !== null && dados?.regimeContratacaoId !== undefined && dados?.regimeContratacaoId !== ''
            ? qte + 1
            : qte + 0,
        );
        setQuantidadeCamposPreenchidos(qte =>
          dados?.rg !== null && dados?.rg !== undefined && dados?.rg !== '' ? qte + 1 : qte + 0,
        );
        setQuantidadeCamposPreenchidos(qte => (dados?.setoresCurriculo?.length > 0 ? qte + 6 : qte + 0));
        setQuantidadeCamposPreenchidos(qte =>
          dados?.sexoId !== null && dados?.sexoId !== undefined && dados?.sexoId !== '' ? qte + 4 : qte + 0,
        );
        setQuantidadeCamposPreenchidos(qte =>
          dados?.telefone1 !== null && dados?.telefone1 !== undefined && dados?.telefone1 !== '' ? qte + 6 : qte + 0,
        );
        setQuantidadeCamposPreenchidos(qte => (dados?.historicoCursos?.length > 0 ? qte + 1 : qte + 0));
        setQuantidadeCamposPreenchidos(qte => (dados?.historicoEducacional?.length > 0 ? qte + 4 : qte + 0));
        setQuantidadeCamposPreenchidos(qte => (dados?.historicoHabilidades?.length > 0 ? qte + 1 : qte + 0));
        setQuantidadeCamposPreenchidos(qte => (dados?.historicoIdiomas?.length > 0 ? qte + 1 : qte + 0));
        setQuantidadeCamposPreenchidos(qte => (dados?.historicoProfissional?.length > 0 ? qte + 4 : qte + 0));
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [dados]); // eslint-disable-line

  const handleSubmit = useCallback(
    async data => {
      setSalvando(true);
      try {
        const { data: updateResponse } = await api.put('curriculos', {
          ...data,
          dataAtual: funcoes.getLocalDate(),
          cnh: data.possuiCnh ? data.cnh : null,
          possuiCnh: +data.possuiCnh,
          possuiFilhos: +data.possuiFilhos,
          possuiAmigoOuFamiliarNaEmpresa: +data.possuiAmigoOuFamiliarNaEmpresa,
          portadorNecessidadeEspecial: +data.portadorNecessidadeEspecial,
          disponibilidadeHorarioTurno: +data.disponibilidadeHorarioTurno,
          estaTrabalhandoAtualmente: +data.estaTrabalhandoAtualmente,
          vagaTemporaria: +data.vagaTemporaria,
          dataAlteracao: funcoes.getLocalDate(),
          usuarioAlteracao: userData.id,
          usuario: { ...data.usuario, file: null },
        });

        // Estou verificando se a data é igual a 01/01/1970 pois muitos usuários estão tendo problemas ao preencher a data de nascimento
        if (
          new Date(data.dataNascimento) &&
          (format(new Date(data.dataNascimento), 'dd/MM/yyyy') === format(new Date('1970-01-01'), 'dd/MM/yyyy') ||
            format(new Date(data.dataNascimento), 'dd/MM/yyyy') === format(new Date('1969-12-31'), 'dd/MM/yyyy'))
        ) {
          enqueueSnackbar(`Verifique se sua data de nascimento está correta. Se sim, pode desconsiderar esta mensagem.`, {
            variant: 'warning',
          });
        }

        if (data.cidade.toLowerCase() === 'brasileira' || data.cidade.toLowerCase() === 'brasileiro') {
          enqueueSnackbar(`Verifique o nome da cidade, não encontramos uma cidade com o nome "BRASILEIRA(O)" no sistema`, {
            variant: 'warning',
          });
        }

        if (!updateResponse.status) {
          enqueueSnackbar(`Erro: Alguns dados podem não terem sidos salvos! Verifique. ${updateResponse.message}`, {
            variant: 'error',
          });
        }
      } catch (err) {
        enqueueSnackbar(`Algo deu errado. Tente novamente. ${err.message}`, { variant: 'error' });
      } finally {
        setSalvando(false);
        setRefreshIframe(funcoes.getRandomInt(1000));
      }
    },
    [], // eslint-disable-line
  );

  const lookCep = useCallback(
    async event => {
      if (
        (event && event.target.value === null) ||
        event.target.value === undefined ||
        event.target.value.toString().match(/\d/g).length < 8
      )
        return;
      dispatch(toggleLoading(true));
      try {
        const { street, neighborhood, city, state } = await cep(event.target.value);
        setDados(dt => ({
          ...dt,
          logradouro: street,
          bairro: neighborhood,
          cidade: city,
          uf: state,
        }));
      } catch (error) {
        enqueueSnackbar(`${error.message}. Verifique o CEP`, { variant: 'warning' });
      }
      dispatch(toggleLoading(false));
    },
    [dispatch, enqueueSnackbar],
  );

  const handleChangeSubmit = useCallback( // eslint-disable-line
    debounce(
      () => {
        submitButton.current.click();
      },
      4000,
      { trailing: true },
    ),
    [],
  );

  const saveImage = useCallback(
    async file => {
      try {
        setUserPhoto(file);
        await storage.doUploadUserPhoto(file, userData.id);
        const imageUrl = await storage.doUserPhotoUrlLink(userData.id);
        setUserPhoto(imageUrl);
        dispatch(updateUserImageURL(imageUrl));
        enqueueSnackbar('Foto salva com sucesso', { variant: 'success' });
        setShowCropper(false);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [dispatch, enqueueSnackbar, userData.id],
  );

  const handleDeletePhoto = useCallback(async () => {
    try {
      await storage.doRemoveUserPhoto(userData.id);
      setUserPhoto(null);
      dispatch(updateUserImageURL(null));
      enqueueSnackbar('Photo removida com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [dispatch, enqueueSnackbar, userData.id]);

  return (
    <Container>
      <Resume>
        <Form onSubmit={handleSubmit} onChange={handleChangeSubmit} initialData={dados} encType="multipart/form-data">
          <SaveButton type="submit" ref={submitButton} className="curriculoSaveButton" />
          {isMobile && (
            <MobileSaveStatus saving={salvando || carregando}>
              {salvando || carregando ? (
                <MdCached className="rotate" size={32} color="#f47920" />
              ) : (
                <MdCloudDone size={32} color="#00995d" />
              )}
            </MobileSaveStatus>
          )}
          <InputResume id="id" name="id" type="hidden" />
          <div className="subContainer">
            <div className="subSubContainer">
              <div className="titulo">Currículo</div>
              <div>
                <InputProgress text="Preenchimento" percentage={porcentagem} color="#00995d" />
              </div>
              <h5>Em quais setores você tem interesse</h5>
              <ReactSelect
                closeOnSelect={false}
                options={setores}
                multiple
                id="setoresCurriculo"
                name="setoresCurriculo"
                label="Selecione até três setores de seu interesse *"
                fieldPointer="setorId"
                limit={3}
                onBlur={handleChangeSubmit}
              />
              <br />
              <h5>Regime de Contratação *</h5>
              <ReactRadioBig
                name="regimeContratacaoId"
                options={regimesContratacoes}
                regime={regime}
                onChange={setRegime}
                submitHandler={handleChangeSubmit}
              />
              <h5>Dados Pessoais</h5>
              {showCropper && <ReactCropper setShowCropper={setShowCropper} saveImage={saveImage} />}
              <Scope path="usuario">
                <InputResume id="id" name="id" type="hidden" />
                <div className="row-fields">
                  <div className="photo-preview-container">
                    <div className="photo-preview">
                      <img src={preview || UserProfileDefaultImage} alt="Profile" />
                    </div>
                    <div className="button-photo-config">
                      <button type="button" className="edit" onClick={() => setShowCropper(true)}>
                        <MdCreate size={24} /> Editar Foto
                      </button>
                      <button type="button" className="remove" onClick={() => handleDeletePhoto()}>
                        <MdDelete size={24} /> Remover Foto
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row-fields">
                  <div className="column-field">
                    <div className="field-sub-container">
                      <label htmlFor="nome">Nome Completo *</label>
                      <div className="input-container">
                        <InputResume id="nome" name="nome" maxLength={250} />
                      </div>
                    </div>
                  </div>
                  <div className="column-field">
                    <div className="field-sub-container">
                      <label htmlFor="email">Email *</label>
                      <div className="input-container">
                        <InputResume id="email" name="email" maxLength={250} disabled />
                      </div>
                    </div>
                  </div>
                </div>
              </Scope>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="dataNascimento">Data de Nascimento *</label>
                    <div className="input-container">
                      <DatePickerMaterial
                        id="dataNascimento"
                        name="dataNascimento"
                        dateFormat="dd/MM/yyyy"
                        type="keyboardDate"
                        onBlur={handleChangeSubmit}
                      />
                    </div>
                  </div>
                </div>
                <Scope path="usuario">
                  <div className="column-field">
                    <div className="field-sub-container">
                      <label htmlFor="cpf">CPF *</label>
                      <div className="input-container">
                        <InputResume id="cpf" name="cpf" disabled />
                      </div>
                    </div>
                  </div>
                </Scope>
              </div>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="rg">RG</label>
                    <div className="input-container">
                      <InputResume id="rg" name="rg" maxLength={50} />
                    </div>
                  </div>
                </div>
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="pis">PIS/PASEP</label>
                    <div className="input-container">
                      <InputResume id="pis" name="pis" maxLength={50} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="cep">CEP</label>
                    <div className="input-container">
                      <InputResume id="cep" name="cep" onBlur={event => lookCep(event)} maxLength={9} />
                    </div>
                  </div>
                </div>
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="logradouro">Logradouro</label>
                    <div className="input-container">
                      <InputResume id="logradouro" name="logradouro" maxLength={100} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="numero">Número</label>
                    <div className="input-container">
                      <InputResume id="numero" name="numero" maxLength={15} />
                    </div>
                  </div>
                </div>
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="bairro">Bairro</label>
                    <div className="input-container">
                      <InputResume id="bairro" name="bairro" maxLength={50} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="cidade">Cidade</label>
                    <div className="input-container">
                      <InputResume id="cidade" name="cidade" maxLength={80} />
                    </div>
                  </div>
                </div>
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="uf">Estado</label>
                    <div className="input-container">
                      <ReactSelect options={estados} multiple={false} name="uf" label={null} closeOnSelect fieldPointer="uf" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="telefone1">Telefone *</label>
                    <div className="input-container">
                      <InputResume id="telefone1" name="telefone1" maxLength={20} />
                    </div>
                  </div>
                </div>
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="telefone2">Telefone</label>
                    <div className="input-container">
                      <InputResume id="telefone2" name="telefone2" maxLength={20} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="sexoId">Sexo *</label>
                    <div className="input-container">
                      <ReactSelect
                        options={sexos}
                        multiple={false}
                        name="sexoId"
                        label={null}
                        closeOnSelect
                        fieldPointer="sexoId"
                        onBlur={handleChangeSubmit}
                      />
                    </div>
                  </div>
                </div>
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="estadoCivilId">Estado Civil *</label>
                    <div className="input-container">
                      <ReactSelect
                        options={estadosCivis}
                        multiple={false}
                        name="estadoCivilId"
                        label={null}
                        closeOnSelect
                        fieldPointer="estadoCivilId"
                        onBlur={handleChangeSubmit}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="possuiFilhos">Possui Filhos?</label>
                    <ReactSwitch
                      id="possuiFilhos"
                      name="possuiFilhos"
                      textoLigado="Sim"
                      textoDesligado="Não"
                      onChange={setPossuiFilho}
                    />
                  </div>
                </div>
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="disponibilidadeHorarioTurno">Disponibilidade de horário para turnos?</label>
                    <ReactSwitch
                      id="disponibilidadeHorarioTurno"
                      name="disponibilidadeHorarioTurno"
                      textoLigado="Sim"
                      textoDesligado="Não"
                      onChange={setTurno}
                    />
                  </div>
                </div>
              </div>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="possuiAmigoOuFamiliarNaEmpresa">
                      Possui amigo ou parente trabalhando no Grupo Empresarial Unimed?
                    </label>
                    <ReactSwitch
                      id="possuiAmigoOuFamiliarNaEmpresa"
                      name="possuiAmigoOuFamiliarNaEmpresa"
                      textoLigado="Sim"
                      textoDesligado="Não"
                      onChange={setPossuiAmigoOuFamiliar}
                    />
                    {possuiAmigoOuFamiliar && (
                      <>
                        <label style={{ marginTop: 15 }} htmlFor="nomeAmigoOuFamiliarNaEmpresa">
                          Nome do Amigo ou Parentes
                        </label>
                        <div className="input-container">
                          <InputResume id="nomeAmigoOuFamiliarNaEmpresa" name="nomeAmigoOuFamiliarNaEmpresa" maxLength={100} />
                        </div>
                        <label style={{ marginTop: 15 }} htmlFor="grauParentescoAmigoOuFamiliarNaEmpresa">
                          Grau de Parentesco
                        </label>
                        <div className="input-container">
                          <InputResume
                            id="grauParentescoAmigoOuFamiliarNaEmpresa"
                            name="grauParentescoAmigoOuFamiliarNaEmpresa"
                            maxLength={50}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="possuiCnh">Possui CNH?</label>
                    <ReactSwitch name="possuiCnh" id="possuiCnh" textoLigado="Sim" textoDesligado="Não" onClick={toggleCnh} />
                    {possuiCnh && (
                      <>
                        <label style={{ marginTop: 15 }} htmlFor="cnh">
                          Quais Categorias?
                        </label>
                        <div className="input-container">
                          <InputResume id="cnh" name="cnh" maxLength={50} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="portadorNecessidadeEspecial">Pessoa com Deficiência?</label>
                    <ReactSwitch
                      id="portadorNecessidadeEspecial"
                      name="portadorNecessidadeEspecial"
                      textoLigado="Sim"
                      textoDesligado="Não"
                      onChange={setPortadorNecessidadesEspeciais}
                    />
                    {portadorNecessidadesEspeciais && (
                      <>
                        <label style={{ marginTop: 15 }} htmlFor="cidNecessidadeEspecial">
                          CID
                        </label>
                        <div className="input-container">
                          <InputResume id="cidNecessidadeEspecial" name="cidNecessidadeEspecial" maxLength={10} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="escolaridadeId">Escolaridade</label>
                    <div className="input-container">
                      <ReactSelect
                        options={escolaridades}
                        multiple={false}
                        name="escolaridadeId"
                        label={null}
                        closeOnSelect
                        fieldPointer="escolaridadeId"
                        onBlur={handleChangeSubmit}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="estaTrabalhandoAtualmente">Está trabalhando atualmente?</label>
                    <ReactSwitch
                      id="estaTrabalhandoAtualmente"
                      name="estaTrabalhandoAtualmente"
                      textoLigado="Sim"
                      textoDesligado="Não"
                      onChange={setTrabalhandoAtualmente}
                    />
                  </div>
                </div>
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="pretensaoSalarial">Pretenção Salarial</label>
                    <div className="input-container">
                      <InputResume id="pretensaoSalarial" name="pretensaoSalarial" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-fields">
                <div className="column-field">
                  <div className="field-sub-container">
                    <label htmlFor="possuiFilhos">Interesse em vagas temporárias?</label>
                    <ReactSwitch
                      id="vagaTemporaria"
                      name="vagaTemporaria"
                      textoLigado="Sim"
                      textoDesligado="Não"
                      onChange={setVagasTemporarias}
                    />
                  </div>
                </div>
              </div>
              <h5>Histórico Profissional</h5>
              <ItemDescription>
                <MdInfo size={18} style={{ color: '#ffcb08' }} /> Inclua os empregos mais relevantes dos últimos 10 anos e
                períodos nessa seção. Liste a função mais recente de cada emprego e se estiver trabalhando não se esqueça de
                inserí-lo aqui. Insira uma breve descrição do que foi desenvolvido em cada emprego.
              </ItemDescription>
              {historicosProfissionais &&
                historicosProfissionais.map((item, index) => (
                  <Scope path={`historicoProfissional[${index}]`} key={item.id ? item.id : index + 1000}>
                    <HistoricoProfissional
                      rearrange={funcoes.rearrange}
                      remove={funcoes.remove}
                      lastItem={historicosProfissionais.length - 1 === index}
                      itemIndex={index}
                      data={historicosProfissionais}
                      setHistorico={setDados}
                      dadosItem={item}
                      refresh={setRefreshIframe}
                      handleSubmit={handleChangeSubmit}
                      cid={item.cid}
                    />
                  </Scope>
                ))}
              <ButtonAdd
                onClick={() =>
                  funcoes.addHistorico(dados.historicoProfissional, setDados, handleChangeSubmit, 'historicoProfissional')
                }
              >
                + Adicionar Historico Profissional
              </ButtonAdd>
              <h5>Histórico Educacional</h5>
              <ItemDescription>
                <MdInfo size={18} style={{ color: '#ffcb08' }} /> Inclua seu histórico de educação aqui. Caso possua curso
                superior, coloque o registro do seu ensino médio também, caso não possua, insira os dados do sua última graduação.
              </ItemDescription>
              {historicosEducacionais &&
                historicosEducacionais.map((item, index) => (
                  <Scope path={`historicoEducacional[${index}]`} key={item.id ? item.id : index + 2000}>
                    <HistoricoEducacional
                      rearrange={funcoes.rearrange}
                      remove={funcoes.remove}
                      lastItem={historicosEducacionais.length - 1 === index}
                      itemIndex={index}
                      dados={historicosEducacionais}
                      setHistorico={setDados}
                      dadosItem={item}
                      escolaridades={escolaridades}
                      refresh={setRefreshIframe}
                      handleSubmit={handleChangeSubmit}
                      cid={item.cid}
                    />
                  </Scope>
                ))}
              <ButtonAdd
                onClick={() =>
                  funcoes.addHistorico(dados.historicoEducacional, setDados, handleChangeSubmit, 'historicoEducacional')
                }
              >
                + Adicionar Histórico Educacional
              </ButtonAdd>
              <h5>Habilidades</h5>
              <ItemDescription>
                <MdInfo size={18} style={{ color: '#ffcb08' }} /> Insira uma habilidade por linha. Nessa seção insira as
                habilidades relevantes que você possua, como Word, Excel, PowerPoint, Internet, Linguagens de programação,
                Técnicas de Enfermagem específicas, ou alguma habilidade específica da sua atividade, coloque somente o título da
                habilidade, o nome da habilidade e o seu respectivo nível. Insira uma habilidade por vez, por linha.
              </ItemDescription>
              {historicosHabilidades &&
                historicosHabilidades.map((item, index) => (
                  <Scope path={`historicoHabilidades[${index}]`} key={item.id ? item.id : index + 3000}>
                    <HistoricoHabilidades
                      rearrange={funcoes.rearrange}
                      remove={funcoes.remove}
                      lastItem={historicosHabilidades.length - 1 === index}
                      itemIndex={index}
                      data={historicosHabilidades}
                      setHistorico={setDados}
                      dadosItem={item}
                      refresh={setRefreshIframe}
                      handleSubmit={handleChangeSubmit}
                      cid={item.cid}
                    />
                  </Scope>
                ))}
              <ButtonAdd
                onClick={() =>
                  funcoes.addHistorico(dados.historicoHabilidades, setDados, handleChangeSubmit, 'historicoHabilidades')
                }
              >
                + Adicionar Habilidades
              </ButtonAdd>
              <h5>Cursos</h5>
              <ItemDescription>
                <MdInfo size={18} style={{ color: '#ffcb08' }} /> Adicione os cursos que você possui.
              </ItemDescription>
              {historicosCursos &&
                historicosCursos.map((item, index) => (
                  <Scope path={`historicoCursos[${index}]`} key={item.id ? item.id : index + 4000}>
                    <HistoricoCursos
                      rearrange={funcoes.rearrange}
                      remove={funcoes.remove}
                      lastItem={historicosCursos.length - 1 === index}
                      itemIndex={index}
                      data={historicosCursos}
                      setHistorico={setDados}
                      dadosItem={item}
                      refresh={setRefreshIframe}
                      handleSubmit={handleChangeSubmit}
                      cid={item.cid}
                    />
                  </Scope>
                ))}
              <ButtonAdd
                onClick={() => funcoes.addHistorico(dados.historicoCursos, setDados, handleChangeSubmit, 'historicoCursos')}
              >
                + Adicionar Cursos
              </ButtonAdd>
              <h5>Idiomas</h5>
              <ItemDescription>
                <MdInfo size={18} style={{ color: '#ffcb08' }} /> Adicione os idiomas que você possui ou estuda, bem como sua
                proficiência.
              </ItemDescription>
              {historicosIdiomas &&
                historicosIdiomas.map((item, index) => (
                  <Scope path={`historicoIdiomas[${index}]`} key={item.id ? item.id : index + 5000}>
                    <HistoricoIdiomas
                      rearrange={funcoes.rearrange}
                      remove={funcoes.remove}
                      lastItem={historicosIdiomas.length - 1 === index}
                      itemIndex={index}
                      data={historicosIdiomas}
                      setHistorico={setDados}
                      dadosItem={item}
                      refresh={setRefreshIframe}
                      handleSubmit={handleChangeSubmit}
                      cid={item.cid}
                    />
                  </Scope>
                ))}
              <ButtonAdd
                onClick={() => funcoes.addHistorico(dados.historicoIdiomas, setDados, handleChangeSubmit, 'historicoIdiomas')}
              >
                + Adicionar Idiomas
              </ButtonAdd>
            </div>
          </div>
        </Form>
      </Resume>
      {!isMobile && (
        <Preview>
          <Row>
            <DocumentContainer ref={containerRef}>
              <DocumentHeader>
                <div className="saved">
                  {salvando || carregando ? (
                    <MdCached className="rotate" size={24} color="#f47920" />
                  ) : (
                    <MdCloudDone size={24} color="#b1d34b" />
                  )}
                  {carregando ? <span> Carregando...</span> : salvando ? <span> Salvando...</span> : <span> Salvo</span>}
                </div>
                <div className="print">
                  <Link to={routes.RESUME_VIEWER} target="_blank">
                    <MdArrowDownward size={27} />
                    Baixar Currículo
                  </Link>
                </div>
              </DocumentHeader>
              <SubDocumentContainer>
                <Document>
                  <DocumentPosition
                    ref={containerRef}
                    largura={containerRef.current && containerRef.current.children[1].children[0].clientWidth}
                    altura={containerRef.current && containerRef.current.children[1].children[0].clientHeight}
                  >
                    useMemo(
                    <iframe ref={printRef} key={refreshIframe} title="viewer" src={urlResumeViewer} />, [])
                  </DocumentPosition>
                </Document>
              </SubDocumentContainer>
            </DocumentContainer>
          </Row>
        </Preview>
      )}
    </Container>
  );
};

export default memo(Curriculos);
