import React, { useState, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { store } from '~/store';
import { toggleLoading, setCurrentPage } from '~/store/modules/main/actions';

import ProcessStepsIcon from '../../assets/process-steps.svg';
import * as routes from '../../configs/Routes';
import api from '../../services/api';
import {
  Container,
  UpContainer,
  SubUpContainer,
  CardContainer,
  SetorNameContainer,
  NameContainer,
  SemProcessosCadastradosContainer,
} from './styles';
import { math } from 'polished';
import Usuarios from '../Usuarios';


// Função criada para mascarar a url
function maskUrl( ){
  return Math.random();
}





const MeusProcessos = () => {
  const [carregando, setCarregando] = useState(false);
  const [listaProcessos, setListaProcessos] = useState([]);
  const dispatch = useDispatch();
  const { id, userClaimId } = store.getState().user.profile;

  useEffect(() => {
    dispatch(setCurrentPage('Meus Processos Seletivos'));
  }, []); // eslint-disable-line

  useEffect(() => {
    const fetchData = async () => {
      dispatch(toggleLoading(true));
      setCarregando(true);
      const {
        data: { data: dados },
      } = await api.get('processoseletivo/department-process', {
        headers: {
          id,
          claimid: userClaimId,
        },
      });

      setListaProcessos(dados);
      setCarregando(false);
      dispatch(toggleLoading(false));
    };
    fetchData();
  }, [dispatch, id, userClaimId]);

  return (
    <Container>
      {listaProcessos.length > 0 && (
        <UpContainer>
          <SubUpContainer>
            {listaProcessos &&
              listaProcessos.map(item => (
                <CardContainer
                  key={item.id}

                  to={{ pathname: `${routes.SELECTIVE_PROCESS}/${maskUrl(item.id)}`, state: { processoSeletivoId: item.id } }}
                >
                  <div>
                    <SetorNameContainer>
                      <NameContainer>{item.setor.nome}</NameContainer>
                      <Badge pill variant="dark">
                        {item.curriculoEtapaProcessoSeletivos.length}
                      </Badge>
                    </SetorNameContainer>
                    <img src={ProcessStepsIcon} alt="Ícone de Etapas" />
                    <h6>{item.nome}</h6>
                  </div>
                </CardContainer>
              ))}
          </SubUpContainer>
        </UpContainer>
      )}
      {!listaProcessos.length > 0 && (
        <SemProcessosCadastradosContainer>
          {!carregando && <h1>Você não possui processo seletivo em andamento</h1>}
        </SemProcessosCadastradosContainer>
      )}
    </Container>
  );
};

export default MeusProcessos;
